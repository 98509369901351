<template>
  <base-form-item :field="field">
    <vue-editor
        :class="meta && meta.class ? meta.class : ''"
        :customModules="customModulesForEditor"
        :editorOptions="editorSettings"
        :value="value"
        name="testEditor"
        style="display: inline-block; width: 100%;"
        type="text"
        v-model="value"
    >
    </vue-editor>
    <small
        class="help-block"
        v-if="backend_errors[field.dbName] && !checkinputRequired(field.dbName)"
    >
      <span v-html="validationTranslate(backend_errors[field.dbName][0])"></span>
    </small>
  </base-form-item>
</template>

<script>
import {VueEditor} from 'vue2-editor';
import {ImageDrop} from 'quill-image-drop-module';
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';

export default {
  components: {
    VueEditor, BaseFormItem,
  },
  mixins: [abstractForm],
  name: 'CreateOrUpdate',
  data() {
    return {
      customModulesForEditor: [
        {alias: 'imageDrop', module: ImageDrop},
      ],
      editorSettings: {
        modules: {
          imageDrop: true,
        },
      },
    };
  },
  methods: {},
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
//
</style>
